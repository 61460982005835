import * as React from 'react';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Typography from '@mui/material/Typography';
import Badge from '@mui/material/Badge';

export default function SupportHeader(props) {

      const { drawerOpen, open, landingPage, characterItems, ...other } = props;

  return (
    <Box position="sticky" sx={{ width: '100%', color: '#FFF', marginTop: "0", padding: '10px', display: "flex", alignItems: "center"}}>
        <Box sx={{width: '30%'}}>
            <Link href="https://www.patreon.com/ProjectDiablo2" sx={{fontSize: "1rem"}}>Support Project Diablo 2</Link>
            <VolunteerActivismIcon sx={{ color: "#a53030", fontSize: "1.5rem", paddingLeft: "5px"}}/>
        </Box>
        {!landingPage && <Box position="sticky" sx={{width: '100%', display: 'flex', alignItems: 'end', justifyContent: "end"}}>
                <IconButton
                      color="inherit"
                      aria-label="open drawer"
                      edge="end"
                      onClick={drawerOpen}
                      sx={{ ...(open && { display: 'none' }), display: "flex" }}
                    >
                    <Badge badgeContent={characterItems.length} color="primary">
                        <Typography sx={{color: '#FFF', fontSize: '1.3rem', fontWeight: 'bolder'}}>CHARACTER PLANNER</Typography>
                    </Badge>
                    <MenuIcon />
                    </IconButton></Box>}
    </Box>
  );
}