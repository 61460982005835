import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Typography from '@mui/material/Typography';

export default function GearFilter(props) {

    const { setGearFilter, ...other } = props;

    const [value, setValue] = React.useState('All');

    function handleChange(event) {
        setValue(event.target.value);
        setGearFilter(event.target.value);
      };

    return (
        <FormControl sx = {{color: '#FFF', marginTop: '.5em', marginBottom: '.5em', width: '100%' }}>
          <RadioGroup
            row
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="All"
            name="radio-buttons-group"
            value={value}
            sx={{justifyContent: 'center'}}
            onChange={handleChange}
          >
            <FormControlLabel value="All" control={<Radio sx={{ '& .MuiSvgIcon-root': { fontSize: 22,},}} />} label={<Typography sx={{fontSize: '18px'}}>All</Typography>} />
            <FormControlLabel value="Weapon" control={<Radio sx={{ '& .MuiSvgIcon-root': { fontSize: 22,},}} />} label={<Typography sx={{fontSize: '18px'}}>Weapons</Typography>} />
            <FormControlLabel value="Helm" control={<Radio sx={{ '& .MuiSvgIcon-root': { fontSize: 22,},}} />} label={<Typography sx={{fontSize: '18px'}}>Helms</Typography>} />
            <FormControlLabel value="Chest" control={<Radio sx={{ '& .MuiSvgIcon-root': { fontSize: 22,},}} />} label={<Typography sx={{fontSize: '18px'}}>Chests</Typography>} />
            <FormControlLabel value="Shield" control={<Radio sx={{ '& .MuiSvgIcon-root': { fontSize: 22,},}} />} label={<Typography sx={{fontSize: '18px'}}>Shields</Typography>} />
            <FormControlLabel value="Gloves" control={<Radio sx={{ '& .MuiSvgIcon-root': { fontSize: 22,},}} />} label={<Typography sx={{fontSize: '18px'}}>Gloves</Typography>} />
            <FormControlLabel value="Boots" control={<Radio sx={{ '& .MuiSvgIcon-root': { fontSize: 22,},}} />} label={<Typography sx={{fontSize: '18px'}}>Boots</Typography>} />
            <FormControlLabel value="Belt" control={<Radio sx={{ '& .MuiSvgIcon-root': { fontSize: 22,},}} />} label={<Typography sx={{fontSize: '18px'}}>Belts</Typography>} />
            <FormControlLabel value="Amulet" control={<Radio sx={{ '& .MuiSvgIcon-root': { fontSize: 22,},}} />} label={<Typography sx={{fontSize: '18px'}}>Amulets</Typography>} />
            <FormControlLabel value="Ring" control={<Radio sx={{ '& .MuiSvgIcon-root': { fontSize: 22,},}} />} label={<Typography sx={{fontSize: '18px'}}>Rings</Typography>} />
            <FormControlLabel value="Misc." control={<Radio sx={{ '& .MuiSvgIcon-root': { fontSize: 22,},}} />} label={<Typography sx={{fontSize: '18px'}}>Misc.</Typography>} />
          </RadioGroup>
        </FormControl>
    );
}