import React, {useState} from 'react';
import Box from '@mui/material/Box';
import AmazonSkillTree from './amazon-skill-tree.jsx';
import AssassinSkillTree from './assassin-skill-tree.jsx';
import BarbarianSkillTree from './barbarian-skill-tree.jsx';
import DruidSkillTree from './druid-skill-tree.jsx';
import NecromancerSkillTree from './necromancer-skill-tree.jsx';
import PaladinSkillTree from './paladin-skill-tree.jsx';
import SorceressSkillTree from './sorceress-skill-tree.jsx';

export default function SkillTree(props) {

    const { characterSelected, allocSkills, setAllocSkills, skills, remainingPoints, ...other } = props;


    return (
    <Box margin={"3%"} sx={{flexGrow: 1}} >
        {characterSelected === "Amazon" && <AmazonSkillTree skills={skills} allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingPoints}/>}
        {characterSelected === "Assassin" && <AssassinSkillTree skills={skills} allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingPoints}/>}
        {characterSelected === "Barbarian" && <BarbarianSkillTree skills={skills} allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingPoints}/>}
        {characterSelected === "Druid" && <DruidSkillTree skills={skills} allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingPoints}/>}
        {characterSelected === "Necromancer" && <NecromancerSkillTree skills={skills} allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingPoints}/>}
        {characterSelected === "Paladin" && <PaladinSkillTree skills={skills} allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingPoints}/>}
        {characterSelected === "Sorceress" && <SorceressSkillTree skills={skills} allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingPoints}/>}
    </Box>
    );
}