import * as React from 'react';
import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Container from '@mui/material/Container';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import AddBoxIcon from '@mui/icons-material/AddBox';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

export default function GearCard(props) {

    const {item, customKey, cardIndex, showModalHandler, query, characterItems, setCharacterItems, toastOpen, setToastOpen, ...other} = props;

    const handleClose = () => {
        setToastOpen(false);
      };

    let shouldScaleImage = false;
    let scaleImages = ["Amulet", "Ring", "Socketable", "Set"];

    if (item.gearId && scaleImages.includes(item.gearId.itemCategory)) {
        shouldScaleImage = true;
    } else if (item.corruptionId && scaleImages.includes(item.corruptionId.itemType)) {
        shouldScaleImage = true;
    } else if (item.affixId && scaleImages.includes(item.itemType)) {
        shouldScaleImage = true;
    } else if (scaleImages.includes(item.itemType)) {
        shouldScaleImage = true;
    }

    const handleAddToCharacterItem = () => {
        if(characterItems.filter(e => e.gearId.name === item.gearId.name).length === 0 || (item.gearId.itemCategory === "Ring" && characterItems.filter(e => e.gearId.name === item.gearId.name).length < 2)) {
            setToastOpen(true);
            setCharacterItems([item, ...characterItems]);
        }
    }

    let runeMap = {
        "El": "https://static.miraheze.org/projectdiablo2wiki/8/88/RuneEl.png",
        "Eld": "https://static.miraheze.org/projectdiablo2wiki/f/f5/RuneEld.png",
        "Tir": "https://static.miraheze.org/projectdiablo2wiki/3/33/RuneTir.png",
        "Nef": "https://static.miraheze.org/projectdiablo2wiki/1/11/RuneNef.png",
        "Eth": "https://static.miraheze.org/projectdiablo2wiki/3/37/RuneEth.png",
        "Ith": "https://static.miraheze.org/projectdiablo2wiki/0/00/RuneIth.png",
        "Tal": "https://static.miraheze.org/projectdiablo2wiki/b/bd/RuneTal.png",
        "Ral": "https://static.miraheze.org/projectdiablo2wiki/d/d3/RuneRal.png",
        "Ort": "https://static.miraheze.org/projectdiablo2wiki/2/25/RuneOrt.png",
        "Thul": "https://static.miraheze.org/projectdiablo2wiki/4/48/RuneThul.png",
        "Amn": "https://static.miraheze.org/projectdiablo2wiki/9/93/RuneAmn.png",
        "Sol": "https://static.miraheze.org/projectdiablo2wiki/6/61/RuneSol.png",
        "Shael": "https://static.miraheze.org/projectdiablo2wiki/f/f6/RuneShael.png",
        "Dol": "https://static.miraheze.org/projectdiablo2wiki/8/81/RuneDol.png",
        "Hel": "https://static.miraheze.org/projectdiablo2wiki/f/f3/RuneHel.png",
        "Io": "https://static.miraheze.org/projectdiablo2wiki/c/c4/RuneIo.png",
        "Lum": "https://static.miraheze.org/projectdiablo2wiki/a/af/RuneLum.png",
        "Ko": "https://static.miraheze.org/projectdiablo2wiki/2/26/RuneKo.png",
        "Fal": "https://static.miraheze.org/projectdiablo2wiki/5/59/RuneFal.png",
        "Lem": "https://static.miraheze.org/projectdiablo2wiki/2/26/RuneLem.png",
        "Pul": "https://static.miraheze.org/projectdiablo2wiki/1/1c/RunePul.png",
        "Um": "https://static.miraheze.org/projectdiablo2wiki/3/39/RuneUm.png",
        "Mal": "https://static.miraheze.org/projectdiablo2wiki/e/e0/RuneMal.png",
        "Ist": "https://static.miraheze.org/projectdiablo2wiki/9/99/RuneIst.png",
        "Gul": "https://static.miraheze.org/projectdiablo2wiki/6/65/RuneGul.png",
        "Vex": "https://static.miraheze.org/projectdiablo2wiki/f/fb/RuneVex.png",
        "Ohm": "https://static.miraheze.org/projectdiablo2wiki/8/8b/RuneOhm.png",
        "Lo": "https://static.miraheze.org/projectdiablo2wiki/a/a9/RuneLo.png",
        "Sur": "https://static.miraheze.org/projectdiablo2wiki/a/a3/RuneSur.png",
        "Ber": "https://static.miraheze.org/projectdiablo2wiki/2/22/RuneBer.png",
        "Jah": "https://static.miraheze.org/projectdiablo2wiki/8/82/RuneJah.png",
        "Cham": "https://static.miraheze.org/projectdiablo2wiki/5/5c/RuneCham.png",
        "Zod": "https://static.miraheze.org/projectdiablo2wiki/1/1c/RuneZod.png"
    }

    let cardMediaStyle = shouldScaleImage ?
        { padding: '1em', objectFit: 'contain', backgroundColor: '#191919', maxWidth: '30%', margin: 'auto'}
        : { padding: '1em', objectFit: 'contain', backgroundColor: '#191919'};
    return (
        <Grid item sm={6} md={4} lg={3} xl={2}>
            <Card
              sx={{ height: '100%', display: 'flex', flexDirection: 'column', border: '5px solid #a53030', backgroundColor: '#191919' }}
            >
              <CardMedia
                component="img"
                image={item.imageUrl}
                width="60px"
                height="150px"
                alt="item"
                sx={cardMediaStyle}
              />
              <CardContent sx={{ flexGrow: 1, backgroundColor: '#301D1D', color: '#FFF', borderTop: '5px solid #a53030' }}>
                    <Typography key={`item-name-${cardIndex}`} gutterBottom variant="h6" component="h6" mb="0" noWrap title={item.gearId && item.gearId.name} >
                      {item.gearId && item.gearId.name}
                      {item.name && item.name}
                      {item.rarity && item.rarity}
                      {item.affixId && item.affixId.name}
                    </Typography>

                    <Typography key={`item-type-${cardIndex}`} gutterBottom variant="caption">
                      {item.itemType && item.itemType.replaceAll("/", " / ")}
                      {item.corruptionId && item.corruptionId.itemType}
                    </Typography>
                    <Typography key={`item-requirements-${cardIndex}`} gutterBottom variant="caption" sx={{display: 'block'}} mb="0" noWrap>{item.requiredLevel && !item.requiredLevel.includes("Set Bonus") && (item.requiredLevel.includes("(") ? item.requiredLevel.substring(0, item.requiredLevel.indexOf("(")) : item.requiredLevel)}</Typography>
                    {
                        item.additionalInfo && item.additionalInfo.split(",").filter(info => info.includes("Required"))
                            .map(reqs => <Typography key={`item-req-${reqs}-${cardIndex}`} gutterBottom variant="caption" sx={{display: 'block'}} mb="0" noWrap>{reqs.includes("(") ? reqs.substring(0, reqs.indexOf("(")) : reqs}</Typography>)
                    }
                    { item.runes && <Stack direction="row" sx={{ width: '100%' }} spacing={1}>
                        {item.runes.split(", ").map((rune,i) => (
                          <img src={runeMap[rune]} key={`${rune}-${i}`} title={rune}/>
                        ))}
                        </Stack>
                      }
              </CardContent>
              <CardContent sx={{ backgroundColor: '#301D1D', color: '#FFF', display: 'flex', flexDirection: 'column', color: '#EDCF8B'}}>
                    {
                        item.afterAttributes && item.afterAttributes.split(",").filter( attr => attr.toLowerCase().includes(query.toLowerCase()))
                            .map(filteredAttr => <Typography key={`item-after-attrs-${filteredAttr}-${cardIndex}`} gutterBottom variant="subtitle2" mb="0" sx={{display: 'flex', flexDirection: 'column'}}>{filteredAttr}</Typography>)
                    }
                    {
                        item.newAttributes && item.newAttributes.split(",").filter( attr => attr.toLowerCase().includes(query.toLowerCase()))
                            .map(filteredAttr => <Typography key={`item-new-attrs-${filteredAttr}-${cardIndex}`} gutterBottom variant="subtitle2" mb="0" sx={{display: 'flex', flexDirection: 'column'}}>{filteredAttr}</Typography>)
                    }
                    {
                        item.attributes && item.attributes.split(",").filter( attr => attr.toLowerCase().includes(query.toLowerCase()))
                            .map(filteredAttr => <Typography key={`item-attrs-${filteredAttr}-${cardIndex}`} gutterBottom variant="subtitle2" mb="0" sx={{display: 'flex', flexDirection: 'column'}}>{filteredAttr}</Typography>)
                    }
                    {
                        item.corruptionId &&item.corruptionId.attributes.split(",").filter( attr => attr.toLowerCase().includes(query.toLowerCase()))
                            .map(filteredAttr => <Typography key={`item-corruption-attrs-${filteredAttr}-${cardIndex}`} gutterBottom variant="subtitle2" mb="0" sx={{display: 'flex', flexDirection: 'column'}}>{filteredAttr}</Typography>)
                    }
                    {
                        item.weaponAttributes && item.weaponAttributes.split(",").filter( attr => attr.toLowerCase().includes(query.toLowerCase()))
                            .map(filteredAttr => <Typography key={`item-weapon-attrs-${filteredAttr}-${cardIndex}`} gutterBottom variant="subtitle2" mb="0" sx={{display: 'flex', flexDirection: 'column'}}>{filteredAttr}</Typography>)
                    }
                    {
                        item.chestHelmAttributes && item.chestHelmAttributes.split(",").filter( attr => attr.toLowerCase().includes(query.toLowerCase()))
                            .map(filteredAttr => <Typography key={`item-chest-helm-attrs-${filteredAttr}-${cardIndex}`} gutterBottom variant="subtitle2" mb="0" sx={{display: 'flex', flexDirection: 'column'}}>{filteredAttr}</Typography>)
                    }
                    {
                        item.shieldAttributes && item.shieldAttributes.split(",").filter( attr => attr.toLowerCase().includes(query.toLowerCase()))
                            .map(filteredAttr => <Typography key={`item-shield-attrs-${filteredAttr}-${cardIndex}`} gutterBottom variant="subtitle2" mb="0" sx={{display: 'flex', flexDirection: 'column'}}>{filteredAttr}</Typography>)
                    }
              </CardContent>
              <CardActions sx={{borderTop: '5px solid #a53030', backgroundColor: '#a53030'}}>
                <Button size="small" sx={{color: 'text.secondary', margin: 'auto'}} data-key={cardIndex} onClick={(e) => showModalHandler(e)}>View</Button>
                {item.gearId && item.gearId.itemCategory !== "Set" && <IconButton data-key={cardIndex} onClick={handleAddToCharacterItem}><AddBoxIcon /></IconButton>}
                <Snackbar
                        anchorOrigin={{ vertical: "top", horizontal: "right" }}
                        open={toastOpen}
                        onClose={handleClose}
                        autoHideDuration={2000}
                        message="Added item to Character Planner"
                        key={"top-right"}
                      >
                      <Alert severity="success" onClose={handleClose}>Added item to Character Planner</Alert>
                  </Snackbar>
              </CardActions>
            </Card>
          </Grid>
    );

}