import * as React from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
import Badge from '@mui/material/Badge';
import Xarrow from 'react-xarrows';
import SkillButton from './skill-button.jsx';

export default function NecromancerSkillTree(props) {

    const { skills, allocSkills, setAllocSkills, remainingSkillPoints, ...other } = props;

    let skillTreeImg = "./skillTreeBg-2.png";

    return (
        <Box>
            {skills &&
            <Grid container spacing={1}>
                <Grid item className="skillTreeTab" sm={4} md={4} lg={4} xl={4} sx={{ background: `url(${skillTreeImg}) rgba(50,50,50,.1)`, backgroundSize: "contain", border: 'solid 10px #191919', textAlign: 'center'}}>
                    <Toolbar position="relative" sx={{backgroundColor: 'rgba(90,90,90,0.8)', boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', border: 'solid 5px #303030', color: '#FFF', justifyContent: 'center', top: '-28px', width: '75%'}}>
                        <Typography variant="subtitle2" component="div">
                            Summoning Skills
                        </Typography>
                    </Toolbar>
                    <Grid container spacing={1}>
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills['Skeleton Mastery']}/>
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills['Raise Skeleton Warrior']}/>

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />


                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills['Clay Golem']}/>
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills['Golem Mastery']}/>
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills['Raise Skeletal Mage']}/>

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills['Blood Golem']}/>
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills['Blood Warp']}/>
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills['Iron Golem']}/>
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills['Raise Skeleton Archer']}/>

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills['Fire Golem']}/>
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills['Revive']}/>
                    </Grid>
                </Grid>
                <Grid item className="skillTreeTab" sm={4} md={4} lg={4} xl={4} sx={{ background: `url(${skillTreeImg}) rgba(50,50,50,.1)`, backgroundSize: "contain", border: 'solid 10px #191919', textAlign: 'center'}}>
                    <Toolbar position="relative" sx={{backgroundColor: 'rgba(90,90,90,0.8)', boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', border: 'solid 5px #303030', color: '#FFF', justifyContent: 'center', top: '-28px', width: '75%'}}>
                        <Typography variant="subtitle2" component="div">
                            Poison & Bone Skills
                        </Typography>
                    </Toolbar>
                    <Grid container spacing={1}>
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills['Poison Strike']}/>
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills['Teeth']}/>
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills['Corpse Explosion']}/>
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills['Bone Armor']}/>

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills['Desecrate']}/>
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills['Bone Wall']}/>

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills['Bone Spear']}/>
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills['Bone Prison']}/>

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills['Poison Nova']}/>
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills['Bone Spirit']}/>
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                    </Grid>
                </Grid>
                <Grid item className="skillTreeTab" sm={4} md={4} lg={4} xl={4} sx={{ background: `url(${skillTreeImg}) rgba(50,50,50,.1)`, backgroundSize: "contain", border: 'solid 10px #191919', textAlign: 'center'}}>
                    <Toolbar position="relative" sx={{backgroundColor: 'rgba(90,90,90,0.8)', boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', border: 'solid 5px #303030', color: '#FFF', justifyContent: 'center', top: '-28px', width: '75%'}}>
                        <Typography variant="subtitle2" component="div">
                            Curses
                        </Typography>
                    </Toolbar>
                    <Grid container spacing={1}>
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills['Amplify Damage']}/>
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills['Curse Mastery']}/>

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills['Dark Pact']}/>
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills['Dim Vision']}/>

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills['Iron Maiden']}/>
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills['Life Tap']}/>
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills['Terror']}/>

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills['Weaken']}/>
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills['Confuse']}/>

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills['Decrepify']}/>
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills['Attract']}/>

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills['Lower Resist']}/>
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                    </Grid>
                </Grid>
            </Grid>}
            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Raise Skeleton Warrior"
                end="Skeleton Mastery"
                startAnchor="left"
                endAnchor="right" />
            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Raise Skeleton Warrior"
                end="Raise Skeletal Mage"
                startAnchor="bottom"
                endAnchor="top" />
            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Clay Golem"
                end="Golem Mastery"
                startAnchor="bottom"
                endAnchor="top" />
            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Clay Golem"
                end="Blood Golem"
                startAnchor="bottom"
                endAnchor="top" />
            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Raise Skeletal Mage"
                end="Raise Skeleton Archer"
                startAnchor="bottom"
                endAnchor="top" />
            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Blood Golem"
                end="Blood Warp"
                startAnchor="bottom"
                endAnchor="top" />
            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Blood Golem"
                end="Iron Golem"
                startAnchor="bottom"
                endAnchor="top" />
            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Iron Golem"
                end="Fire Golem"
                startAnchor="bottom"
                endAnchor="top" />
            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Raise Skeleton Archer"
                end="Revive"
                startAnchor="bottom"
                endAnchor="top" />



            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Poison Strike"
                end="Corpse Explosion"
                startAnchor="bottom"
                endAnchor="top" />
            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Teeth"
                end="Bone Spear"
                startAnchor="bottom"
                endAnchor="top" />
            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Teeth"
                end="Bone Armor"
                startAnchor="bottom"
                endAnchor="top" />
            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Corpse Explosion"
                end="Desecrate"
                startAnchor="bottom"
                endAnchor="top" />
            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Bone Armor"
                end="Bone Wall"
                startAnchor="bottom"
                endAnchor="top" />
            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Bone Wall"
                end="Bone Prison"
                startAnchor="bottom"
                endAnchor="top" />
            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Desecrate"
                end="Poison Nova"
                startAnchor="bottom"
                endAnchor="top" />
            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Bone Spear"
                end="Bone Spirit"
                startAnchor="bottom"
                endAnchor="top" />
            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Bone Spear"
                end="Bone Prison"
                startAnchor="bottom"
                endAnchor="top" />



            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Amplify Damage"
                end="Dark Pact"
                startAnchor="bottom"
                endAnchor="top" />
            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Amplify Damage"
                end="Life Tap"
                startAnchor="bottom"
                endAnchor="top" />
            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Dark Pact"
                end="Iron Maiden"
                startAnchor="bottom"
                endAnchor="top" />
            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Dim Vision"
                end="Terror"
                startAnchor="bottom"
                endAnchor="top" />
            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Life Tap"
                end="Weaken"
                startAnchor="bottom"
                endAnchor="top" />
            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Terror"
                end="Confuse"
                startAnchor="bottom"
                endAnchor="top" />
            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Weaken"
                end="Decrepify"
                startAnchor="bottom"
                endAnchor="top" />
            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Weaken"
                end="Lower Resist"
                startAnchor="bottom"
                endAnchor="top" />
            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Confuse"
                end="Attract"
                startAnchor="bottom"
                endAnchor="top" />

        </Box>
    );

}