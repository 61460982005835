import * as React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import GearModal from './gear-modal.jsx';
import GearCard from './gear-card.jsx';

export default function ResultsCards(props) {
    const {query, items, tabIndex, gearFilter, characterItems, setCharacterItems, toastOpen, setToastOpen, ...other} = props;

    const [showModal, setShowModal] = React.useState(false);
    const [modelIndex, setModelIndex] = React.useState(0);



    function showModalHandler(e) {
        e.preventDefault()
        setShowModal(!showModal);
        setModelIndex(parseInt(e.target.getAttribute('data-key')));
    }

    let uniqueKeys = [];
    items.forEach((item, i) => {
        let uniqueKey = ""
        if(item.gearId) {
            uniqueKey = `${item.gearId.name}-${item.gearId.itemCategory}-${tabIndex}`;
        } else if(item.corruptionId) {
            uniqueKey = `${item.corruptionId.itemType}-${item.corruptionId.attributes}-${tabIndex}`;
        } else if(item.affixId) {
             uniqueKey = `${item.affixId.name}-${item.affixId.alvl}-${tabIndex}`;
        } else {
            uniqueKey = item.name
        }
        uniqueKeys.push(uniqueKey);
    });

    return (
        <Box>
              <Grid container spacing={10}>
                {(gearFilter !== 'All' && tabIndex === 0 ? items.filter( item => item.gearId.itemCategory === gearFilter) : items).map( (item, i) => ( <GearCard key={uniqueKeys[i]} item={item} cardIndex={i} showModalHandler={showModalHandler} query={query} characterItems={characterItems} setCharacterItems={setCharacterItems} toastOpen={toastOpen} setToastOpen={setToastOpen}/>)
                )}
              </Grid>
              {(gearFilter !== 'All' && tabIndex === 0 ? items.filter( item => item.gearId.itemCategory === gearFilter) : items).map( (item, mIndex) => <GearModal key={uniqueKeys[mIndex]} isOpen={showModal} onModalClose={setShowModal} indexModelClicked={modelIndex} index={mIndex} item={item} /> )}
      </Box>
    );


}