import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import ResultsCards from './results.jsx';
import SearchInput from './search-header.jsx';
import GearFilter from './gear-filter.jsx';
import AppBar from '@mui/material/AppBar';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import {Select, MenuItem} from "@mui/material";
import SortIcon from '@mui/icons-material/Sort';
import SortByAlphaIcon from '@mui/icons-material/SortByAlpha';
import NumbersIcon from '@mui/icons-material/Numbers';
import AddBoxIcon from '@mui/icons-material/AddBox';
import CssBaseline from '@mui/material/CssBaseline';
import { styled, useTheme } from '@mui/material/styles';
import SupportHeader from './support-header.jsx';
import CharacterBuilderDrawer from './character-builder-drawer.jsx';
import GearModal from './gear-modal.jsx';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
      <Box sx={{ p: 6 }}>
        {children}
          </Box>
          )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function CenteredTabs(props) {
  const [value, setValue] = React.useState(0);
  const [sortValue, setSortValue] = React.useState(0);
  const [query, setQuery] = React.useState('');
  const [items, setItems] = React.useState([]);
  const [alert, setAlert] = React.useState(false);
  const [noResults, setNoResults] = React.useState(false);
  const [landingPage, setLandingPage] = React.useState(true);
  const [gearFilter, setGearFilter] = React.useState('All');
  const [characterItems, setCharacterItems] = React.useState([]);
  const [toastOpen, setToastOpen] = React.useState(false);

  const [showModal, setShowModal] = React.useState(false);
  const [modelIndex, setModelIndex] = React.useState(0);

  function showModalHandler(e) {
      e.preventDefault();
      setShowModal(!showModal);
      setModelIndex(parseInt(e.currentTarget.getAttribute('data-key')));
  }

  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleChange = (event, newValue) => {
    if(newValue == 0) {
        setGearFilter('All');
    }
    setValue(newValue);
  };

  const handleSortChange = (event) => {
    if(event === undefined) {
        setSortValue(0);
        return;
    }
    if(items.length > 0) {
        // Alphabetical sort
        if(event.target.value == 0) {
            items[0].data = items[0].data.sort((a,b) => (a.gearId.name > b.gearId.name) ? 1 : ((b.gearId.name > a.gearId.name) ? -1 : 0));
            items[1].data = items[1].data.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
            items[2].data = items[2].data.sort((a,b) => (a.rarity > b.rarity) ? 1 : ((b.rarity > a.rarity) ? -1 : 0));
            items[3].data = items[3].data.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
            items[4].data = items[4].data.sort((a,b) => (a.affixId.name > b.affixId.name) ? 1 : ((b.affixId.name > a.affixId.name) ? -1 : 0));
        }

        // Numerical Sort
        if(event.target.value == 1) {
            items[0].data = items[0].data.sort((a,b) => {
                var aFilteredAttr = a.afterAttributes && a.afterAttributes.split(",").filter( attr => attr.toLowerCase().includes(query.toLowerCase()));
                if(aFilteredAttr.length === 0) {
                    aFilteredAttr = a.newAttributes && a.newAttributes.split(",").filter( attr => attr.toLowerCase().includes(query.toLowerCase()));
                }
                var bFilteredAttr = b.afterAttributes && b.afterAttributes.split(",").filter( attr => attr.toLowerCase().includes(query.toLowerCase()));
                if(bFilteredAttr.length === 0) {
                    bFilteredAttr = b.newAttributes && b.newAttributes.split(",").filter( attr => attr.toLowerCase().includes(query.toLowerCase()));
                }
                if(aFilteredAttr.length > 0 && bFilteredAttr.length > 0) {
                    let regex = /\d+/;
                    let aAttrVal = aFilteredAttr[0].match(regex);
                    let bAttrVal = bFilteredAttr[0].match(regex);
                    return parseInt(aAttrVal) - parseInt(bAttrVal);
                } else {
                    return -1;
                }

             });
             items[1].data = items[1].data.sort((a,b) => {
                let regex = /\d+/;
                var aWeapFilteredAttr = a.weaponAttributes.split(",").filter( attr => attr.toLowerCase().includes(query.toLowerCase()));
                var aChestArmorFilteredAttr = a.chestHelmAttributes.split(",").filter( attr => attr.toLowerCase().includes(query.toLowerCase()));
                var aShieldFilteredAttr = a.shieldAttributes.split(",").filter( attr => attr.toLowerCase().includes(query.toLowerCase()));
                var bWeapFilteredAttr = b.weaponAttributes.split(",").filter( attr => attr.toLowerCase().includes(query.toLowerCase()));
                var bChestArmorFilteredAttr = b.chestHelmAttributes.split(",").filter( attr => attr.toLowerCase().includes(query.toLowerCase()));
                var bShieldFilteredAttr = b.shieldAttributes.split(",").filter( attr => attr.toLowerCase().includes(query.toLowerCase()));
                var aAttrVal = aWeapFilteredAttr.length > 0 && aWeapFilteredAttr[0].match(regex) || aChestArmorFilteredAttr.length > 0 && aChestArmorFilteredAttr[0].match(regex) || aShieldFilteredAttr.length > 0 && aShieldFilteredAttr[0].match(regex);
                var bAttrVal = bWeapFilteredAttr.length > 0 && bWeapFilteredAttr[0].match(regex) || bChestArmorFilteredAttr.length > 0 && bChestArmorFilteredAttr[0].match(regex) || bShieldFilteredAttr.length > 0 && bShieldFilteredAttr[0].match(regex);
                return parseInt(aAttrVal) - parseInt(bAttrVal);
             });
             items[2].data = items[2].data.sort((a,b) => {
                let regex = /\d+/;
                var aFilteredAttr = a.corruptionId.attributes.split(",").filter( attr => attr.toLowerCase().includes(query.toLowerCase()));
                var bFilteredAttr = b.corruptionId.attributes.split(",").filter( attr => attr.toLowerCase().includes(query.toLowerCase()));
                var aAttrVal = aFilteredAttr[0].match(regex);
                var bAttrVal = bFilteredAttr[0].match(regex);
                return parseInt(aAttrVal) - parseInt(bAttrVal);
             });
             items[3].data = items[3].data.sort((a,b) => {
                let regex = /\d+/;
                var aFilteredAttr = a.attributes.split(",").filter( attr => attr.toLowerCase().includes(query.toLowerCase()));
                var bFilteredAttr = b.attributes.split(",").filter( attr => attr.toLowerCase().includes(query.toLowerCase()));
                var aAttrVal = aFilteredAttr[0].match(regex);
                var bAttrVal = bFilteredAttr[0].match(regex);
                return parseInt(aAttrVal) - parseInt(bAttrVal);
             });
             items[4].data = items[4].data.sort((a,b) => {
                let regex = /\d+/;
                var aFilteredAttr = a.attributes.split(",").filter( attr => attr.toLowerCase().includes(query.toLowerCase()));
                var bFilteredAttr = b.attributes.split(",").filter( attr => attr.toLowerCase().includes(query.toLowerCase()));
                var aAttrVal = aFilteredAttr[0].match(regex);
                var bAttrVal = bFilteredAttr[0].match(regex);
                return parseInt(aAttrVal) - parseInt(bAttrVal);
             });
        }

        // Requirements Sort
        if(event.target.value == 2) {
            items[0].data = items[0].data.sort((a,b) => {
                let regex = /\d+/;
                let aReqLvl = a.requiredLevel.match(regex);
                let bReqLvl = b.requiredLevel.match(regex);
                if (a.requiredLevel.includes("Set")) {
                    return -1;
                } else if (b.requiredLevel.includes("Set")) {
                    return -1;
                } else {
                    return parseInt(aReqLvl) - parseInt(bReqLvl);
                }
            });
            items[1].data = items[1].data.sort((a,b) => parseInt(a.requiredLevel) - parseInt(b.requiredLevel));
            items[4].data = items[4].data.sort((a,b) => parseInt(a.affixId.alvl) - parseInt(b.affixId.alvl));
        }
    }
    setSortValue(event.target.value);
  };

  const handleDrawerOpen = () => {
      setOpen(true);
  };

  const handleDrawerClose = () => {
      setOpen(false);
  };

  let myStyles = {
        position: 'absolute',
        left: 0,
        right: 0,
        marginLeft: 'auto',
        marginRight: 'auto',
        transform: 'translate(0, 25%)',
        width: '100%',
        height: '100%',
        backgroundColor: '#191919',
  };

    let alertStyle = {
          marginLeft: '21%',
          marginRight: '21%',
    };

    const drawerWidth = 400;

    const DrawerHeader = styled('div')(({ theme }) => ({
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: 'flex-start',
    }));

    const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
      ({ theme, open }) => ({
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        marginRight: 0,
        marginTop: '-4%',
        ...(open && {
          transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
          }),
          marginRight: `calc(0% + ${drawerWidth}px)`,
        }),
      }),
    );

  return (
    <Box sx={{ width: '100%', height: '100%' }}>
        <AppBar open={open} elevation={0} position="sticky" sx={landingPage ? myStyles : { backgroundColor: '#191919'}}>
              <SupportHeader open={open} drawerOpen={handleDrawerOpen} landingPage={landingPage} characterItems={characterItems}/>
              <SearchInput onQuery={setQuery} sortChange={handleSortChange} setActiveTab={setValue} setNoResults={setNoResults} setAlert={setAlert} landingPage={landingPage} setLandingPage={setLandingPage} onFetchItems={setItems} />
              { alert && <Alert sx={landingPage ? alertStyle : {marginLeft: '15%', marginRight: '16%'}} severity="error">Invalid request: Try searching for a specific attribute (ex. faster cast rate)</Alert> }
              { noResults && <Alert sx={{marginLeft: '15%', marginRight: '16%'}} severity="error">No results found</Alert> }
          { !landingPage && <Box sx={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}><Tabs value={value} onChange={handleChange} centered>
            <Tab sx={{ fontWeight: 'bolder'}} label="Gear" />
            <Tab sx={{ fontWeight: 'bolder'}} label="Socketables" />
            <Tab sx={{ fontWeight: 'bolder'}} label="Corruptions" />
            <Tab sx={{ fontWeight: 'bolder'}} label="Crafts" />
            <Tab sx={{ fontWeight: 'bolder'}} label="Affixes" />
          </Tabs>
          <Select
              title="Sort"
              sx={{"& .MuiSvgIcon-root": { color: "#a53030" }, boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 }, border: 'none', fontSize: '1rem', color: "#FFF", paddingRight: "30px", '& .MuiSelect-select': {display: "flex", alignItems: "center"}, marginRight: "40px", display: 'flex', alignItems: 'end', justifyContent: 'end', textColor: "#FFF"}}
              color="primary"
              value={sortValue}
              IconComponent={SortIcon}
              onChange={handleSortChange}>
              <MenuItem value={0}><SortByAlphaIcon sx={{ textColor: "#FFF", fontSize: "2rem", paddingRight: "5px"}}/>Alphabetical</MenuItem>
              <MenuItem value={1}><NumbersIcon sx={{ textColor: "#FFF", fontSize: "2rem", paddingRight: "5px"}}/>Numerical</MenuItem>
              <MenuItem value={2}><AddBoxIcon sx={{ textColor: "#FFF", fontSize: "2rem", paddingRight: "5px"}}/>Level Req.</MenuItem>
          </Select>
          </Box>
          }
          { items.length > 0 && value == 0 && <GearFilter setGearFilter={setGearFilter}/> }
        </AppBar>
        <Main id="results-box" open={open}>
            <DrawerHeader />
          <TabPanel value={value} index={0}>
                <ResultsCards tabIndex={0} gearFilter={gearFilter} query={query} items={items[0] ? items[0].data : []} characterItems={characterItems} setCharacterItems={setCharacterItems} toastOpen={toastOpen} setToastOpen={setToastOpen}/>
          </TabPanel>
          <TabPanel value={value} index={1}  items={items[1] ? items[1].data : []}>
                <ResultsCards tabIndex={1} gearFilter={gearFilter} query={query} items={items[1] ? items[1].data : []}/>
          </TabPanel>
          <TabPanel value={value} index={2} items={items[2] ? items[2].data : []}>
                <ResultsCards tabIndex={2} gearFilter={gearFilter} query={query} items={items[2] ? items[2].data : []}/>
          </TabPanel>
          <TabPanel value={value} index={3} items={items[3] ? items[3].data : []}>
                <ResultsCards tabIndex={3} gearFilter={gearFilter} query={query} items={items[3] ? items[3].data : []}/>
          </TabPanel>
          <TabPanel value={value} index={4} items={items[4] ? items[4].data : []}>
                <ResultsCards tabIndex={4} gearFilter={gearFilter} query={query} items={items[4] ? items[4].data : []}/>
          </TabPanel>
          </Main>
          <CharacterBuilderDrawer drawerClose={handleDrawerClose} theme={theme} open={open} drawerWidth={drawerWidth} characterItems={characterItems} setCharacterItems={setCharacterItems} showModalHandler={showModalHandler}/>
          {characterItems.map((item, mIndex) => <GearModal key={`${item.gearId.name}-${mIndex}`} isOpen={showModal} onModalClose={setShowModal} indexModelClicked={modelIndex} index={mIndex} item={item} />)}
    </Box>
  );
}