import * as React from 'react';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

const style = {
  position: 'absolute',
  top: '100%',
  left: '100%',
  transform: 'translate(-35%, -50%)',
  width: '150%',
  backgroundColor: '#5F2525',
  color: '#FFF',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function AttributesDescription(props) {
    const { attrs, weaponAttrs, chestHelmAttrs, shieldAttrs, ...other} = props;
    return (
        <>
              {attrs[0] && attrs[0].map((txt) => <Typography key={txt} variant="h6" sx={{display: 'block'}}>{txt}</Typography>) }
              {attrs[1] && attrs[1].map((txt) => <Typography key={txt} variant="h6" sx={{display: 'block'}}>{txt}</Typography>) }
              {attrs[2] && attrs[2].map((txt) => <Typography key={txt} variant="h6" sx={{display: 'block'}}>{txt}</Typography>) }
              {attrs[3] && attrs[3].map((txt) => <Typography key={txt} variant="h6" sx={{display: 'block'}}>{txt}</Typography>) }
              {weaponAttrs[0] && <><Typography variant="caption">Weapon: </Typography> {weaponAttrs[0].map((txt) => <Typography key={txt}>{txt}</Typography>)}</> }
              {chestHelmAttrs[0] && <><Typography variant="caption">Chest/Helm: </Typography> {chestHelmAttrs[0].map((txt) => <Typography key={txt}>{txt}</Typography>)}</> }
              {shieldAttrs[0] && <><Typography variant="caption">Shield: </Typography> {shieldAttrs[0].map((txt) => <Typography key={txt}>{txt}</Typography>)}</> }
        </>
    );
}

export default function GearModal(props) {
    const {item, isOpen, indexModelClicked, onModalClose, index, ...other} = props;

      const [open, setOpen] = React.useState(false);
      const handleOpen = () => setOpen(true);
      const handleClose = () => {
        onModalClose(false);
        setOpen(false);
      }

    if(isOpen && !open && index === indexModelClicked) {
        setOpen(true)
    }

    let attrs = [];
    attrs.push(item.afterAttributes && item.afterAttributes.split(", "))
    attrs.push(item.newAttributes && item.newAttributes.split(", "))
    attrs.push(item.attributes && item.attributes.split(", "))
    attrs.push(item.corruptionId && item.corruptionId.attributes.split(", "))

    return (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{width: '40%', height: '60%'}}
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" key="item-name" variant="h6" component="h2">
                {item.number && `#${item.number} -`} {item.gearId ? item.gearId.name : item.name}
                {item.rarity && item.rarity}
                {item.affixId && item.affixId.name}
            </Typography>
            <Typography id="modal-modal-description" key="item-type" variant="caption" sx={{ mt: 2, display: 'block' }}>
              {item.itemType && item.itemType}
            </Typography>
            {
                item.additionalInfo && item.additionalInfo.split(",")
                    .map(info => <Typography gutterBottom key={info} variant="caption" sx={{display: 'block'}} mb="0" noWrap>{info}</Typography>)
            }
            <Typography id="modal-modal-description" key="required-level" variant="caption" sx={{ mt: 2, display: 'block' }}>
              {item.requiredLevel && !item.requiredLevel.includes("Set Bonus") && (item.requiredLevel.includes("Required") ? item.requiredLevel : `Required Level: ${item.requiredLevel}`)}
            </Typography>
            <Typography id="modal-modal-description" key="item-runes" sx={{ mt: 2 }}>
                {item.gemRequired && `${item.gemRequired} +`} {item.runeRequired && item.runeRequired}
                {item.runes && item.runes}
            </Typography>
            <Typography id="modal-modal-description" key="corruption-item-type" sx={{ mt: 2 }}>
              {item.corruptionId && item.corruptionId.itemType}
            </Typography>
            <AttributesDescription
                attrs={[...attrs]}
                weaponAttrs={[item.weaponAttributes && item.weaponAttributes.split(", ")]}
                chestHelmAttrs={[item.chestHelmAttributes && item.chestHelmAttributes.split(", ")]}
                shieldAttrs={[item.shieldAttributes && item.shieldAttributes.split(", ")]}
            />
          </Box>
        </Modal>
    );
}