import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import CharacterBuilderDrawerListItem from './character-builder-drawer-list-item.jsx';
import Button from '@mui/material/Button';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import AddBoxIcon from '@mui/icons-material/AddBox';
import CharacterPlanner from './character-planner.jsx';
import axios from 'axios';

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start',
}));

export default function CharacterBuilderDrawer(props) {

    const { drawerClose, theme, open, drawerWidth, characterItems, setCharacterItems, showModalHandler, ...other } = props;
    const [plannerOpen, setPlannerOpen] = React.useState(false);
    const [skills, setSkills] = React.useState([]);

    const DrawerHeader = styled('div')(({ theme }) => ({
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: 'flex-start',
      backgroundColor: '#191919',
    }));

     const handleOpenSkillSheet = () => {
        axios.get(`/api/v1/skill`).then((resp) => {
            const skillsMap = resp.data.reduce((map, skill) => {
                map[skill.name] = skill;
                return map;
            }, {});
            setSkills(skillsMap);
            setPlannerOpen(true);
        });
     };

     const handleClose = () => {
        setPlannerOpen(false);
    };

    return (
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              backgroundColor: '#505050',
            },
          }}
          variant="persistent"
          anchor="right"
          open={open}
        >
          <DrawerHeader>
            <IconButton onClick={drawerClose}>
              {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
              <Typography sx={{color: "#a53030", fontSize: "1.3rem", fontWeight: 'bolder' }}>CHARACTER PLANNER</Typography>
            </IconButton>
          </DrawerHeader>
          <Divider />
          <List>
            <CharacterBuilderDrawerListItem titleKey='Amulets' itemList={characterItems.filter(item => item.gearId.itemCategory === "Amulet")} itemCategoryIcon={"/amulet-icon.svg"} fullItemList={characterItems} updateItemList={setCharacterItems} showModalHandler={showModalHandler}/>
            <CharacterBuilderDrawerListItem titleKey='Belts' itemList={characterItems.filter(item => item.gearId.itemCategory === "Belt")} itemCategoryIcon={"/belt-icon.svg"} fullItemList={characterItems} updateItemList={setCharacterItems} showModalHandler={showModalHandler}/>
            <CharacterBuilderDrawerListItem titleKey='Boots' itemList={characterItems.filter(item => item.gearId.itemCategory === "Boots")} itemCategoryIcon={"/boots-icon.svg"} fullItemList={characterItems} updateItemList={setCharacterItems} showModalHandler={showModalHandler}/>
            <CharacterBuilderDrawerListItem titleKey='Charms' itemList={characterItems.filter(item => item.gearId.itemCategory === "Misc.")} itemCategoryIcon={"/charms-icon.svg"} fullItemList={characterItems} updateItemList={setCharacterItems} showModalHandler={showModalHandler}/>
            <CharacterBuilderDrawerListItem titleKey='Chests' itemList={characterItems.filter(item => item.gearId.itemCategory === "Chest")} itemCategoryIcon={"/chests-icon.svg"} fullItemList={characterItems} updateItemList={setCharacterItems} showModalHandler={showModalHandler}/>
            <CharacterBuilderDrawerListItem titleKey='Gloves' itemList={characterItems.filter(item => item.gearId.itemCategory === "Gloves")} itemCategoryIcon={"/gloves-icon.svg"} fullItemList={characterItems} updateItemList={setCharacterItems} showModalHandler={showModalHandler}/>
            <CharacterBuilderDrawerListItem titleKey='Helmets' itemList={characterItems.filter(item => item.gearId.itemCategory === "Helm")} itemCategoryIcon={"/helmet-icon.svg"} fullItemList={characterItems} updateItemList={setCharacterItems} showModalHandler={showModalHandler}/>
            <CharacterBuilderDrawerListItem titleKey='Shields' itemList={characterItems.filter(item => item.gearId.itemCategory === "Shield")} itemCategoryIcon={"/shield-icon.svg"} fullItemList={characterItems} updateItemList={setCharacterItems} showModalHandler={showModalHandler}/>
            <CharacterBuilderDrawerListItem titleKey='Weapons' itemList={characterItems.filter(item => item.gearId.itemCategory === "Weapon")} itemCategoryIcon={"/weapons-icon.svg"} fullItemList={characterItems} updateItemList={setCharacterItems} showModalHandler={showModalHandler}/>
            <CharacterBuilderDrawerListItem titleKey='Rings' itemList={characterItems.filter(item => item.gearId.itemCategory === "Ring")} itemCategoryIcon={"/ring-icon.svg"} fullItemList={characterItems} updateItemList={setCharacterItems} showModalHandler={showModalHandler}/>
             <ListItem key={"OpenCharacterBuilder"} disablePadding>
               <Button sx={{width: "100%"}} variant="contained" endIcon={<AddBoxIcon />} onClick={handleOpenSkillSheet} >
                 Start Planning
               </Button>
             </ListItem>
          </List>
          <CharacterPlanner plannerOpen={plannerOpen} setPlannerOpen={setPlannerOpen} handleClose={handleClose} characterItems={characterItems} skills={skills} setAllSkills={setSkills}/>
        </Drawer>
      );
    }