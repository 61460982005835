import * as React from 'react';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import DirectionsIcon from '@mui/icons-material/Directions';
import { useQuery } from "react-query";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import AffixAutoComplete from './affix-autocomplete.jsx';

export default function SearchInput(props) {

  const [value, setValue] = React.useState('');
  const [open, setOpen] = React.useState(false);

  const { onQuery, sortChange, onFetchItems, landingPage, setActiveTab, setNoResults, setLandingPage, setAlert, ...other } = props;


  const handleChange = (event, val) => {
    setValue(event.target.value);
  };

  let landingPageStyle = {
        p: '10px 40px',
        display: 'flex',
        alignItems: 'center',
        width: '100%'
  };

  let panelStyle = {
        p: '10px 40px',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        marginRight: '15%'
  };

   const fetchAllItems = async (val) => {
      try {
          const uris = ["gear", "socketable", "corruption", "craft", "affix"];
          var requests = [];
          uris.forEach(uri => {
              requests.push(axios.get(`/api/v1/${uri}?search=${val}`));
          });
          await Promise.all(requests).then(resp => {
                    onFetchItems(resp);
                    setAlert(false);
                    if(resp[0] && resp[0].data.length <= 0
                        && resp[1] && resp[1].data.length <= 0
                        && resp[2] && resp[2].data.length <= 0
                        && resp[3] && resp[3].data.length <= 0
                        && resp[4] && resp[4].data.length <= 0) {
                            setNoResults(true);
                        } else {
                            setNoResults(false);
                        }
                    if(resp[0] && resp[0].data.length > 0) {
                        setActiveTab(0);
                    } else if(resp[1] && resp[1].data.length > 0) {
                        setActiveTab(1);
                    } else if(resp[2] && resp[2].data.length > 0) {
                        setActiveTab(2);
                    } else if(resp[3] && resp[3].data.length > 0) {
                        setActiveTab(3);
                    } else if(resp[4] && resp[4].data.length > 0) {
                        setActiveTab(4);
                    }
                  return resp;
              });
      } catch (err) {
          console.error(err);
          setAlert(true);
      }
    };

    const submit = (e, val) => {
        e.preventDefault();
        landingPage && setLandingPage(false);
        onQuery(val ? val : value);
        setValue(val ? val : value);
        setOpen(false);

        fetchAllItems(val ? val : value);
        sortChange();
    };

  return (
    <Box>
      { landingPage && <Box sx={{width: '100%', display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
          <img style={{filter: 'hue-rotate(150deg)'}} src="https://projectdiablo2.com/image/portal.png" width="7%" height="8%"/>
          <img src="/pd2-search.png" width="20%" height="24%"/>
          <Typography variant="h6" sx={{width: '50%', margin: '0 20%', textAlign: 'center'}}>Welcome to PD2 Search - the ultimate item archive for optimizing your build</Typography>
      </Box> }
      <Box sx={{ p: '10px 40px', display: 'flex', alignItems: 'center'}} mx={{xs:1, sm:10, md:10, lg:25, xl:50}}>

            { !landingPage && <Box sx={{width: '20%', display: 'flex', alignItems: 'center'}} component="a" href="/">
                  <img style={{filter: 'hue-rotate(150deg)'}} src="https://projectdiablo2.com/image/portal.png" width="30%" height="40%"/>
                  <img src="/pd2-search.png" width="50%" height="60%"/>
              </Box> }
            <Paper
              style={{backgroundColor: "#a53030"}}
              sx={landingPage ? landingPageStyle : panelStyle}
              elevation={5}
              component="form"
              onSubmit={submit}
            >
              <Paper
                  style={{backgroundColor: "#FFFFFF"}}
                    sx={{ display: 'flex', alignItems: 'center', width: '100%', height: '40%' }}
                  >
                     <AffixAutoComplete open={open} setOpen={setOpen} updateValue={setValue} handleTextChange={handleChange} handleSubmit={submit} />
              </Paper>
              <IconButton type="button" sx={{ p: '10px' }} title="Search" onClick={submit}>
                <SearchIcon />
              </IconButton>
            </Paper>
        </Box>
    </Box>
  );
}

