import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';

const fetchAllAffixes = async () => {
      try {
          const uris = ["gear", "affix"];
            var requests = [];
            uris.forEach(uri => {
                requests.push(axios.get(`/api/v1/${uri}`));
            });
            return await Promise.all(requests)
      } catch (err) {
          console.error(err);
      }
}

export default function AffixAutoComplete(props) {

  const [options, setOptions] = React.useState([]);
  const { handleTextChange, handleSubmit, updateValue, open, setOpen, ...other } = props;
  const [inputValue, setInputValue] = React.useState('');
  const loading = open && options.length === 0;

React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
       await fetchAllAffixes().then( resp => {

        const bigRegex = /\(\+[0-9]\.[0-9]{1,3} per Level\)|[0-9]{1,3}\% Chance to Cast Level [0-9]{1,3}|[0-9]{1,3}|[\%\-\+\[\]]|\, \+[0-9]{1,3} to Attack Rating|over\s|seconds|\(Sorceress Only\)|\(Necromancer Only\)|\(Druid Only\)|\(Paladin Only\)|\(Assassin Only\)|\(Amazon Only\)|\(Barbarian Only\)|\([0-9]{1,3} second chill\)|Socketed \([0-9]{1,3}\)|\([0-9]{1,3} charges\)|\([0-9]{1,3}\% per Level\)|Adds \[[0-9]{1,3}\-[0-9]{1,3}\] to \[[0-9]{1,3}\-[0-9]{1,3}\]|Adds [0-9]{1,3} to \[[0-9]{1,3}\-[0-9]{1,3}\]|\, \+\[[0-9]{1,3}\-[0-9]{1,3}\] to Attack Rating|\, \+\[[0-9]{1,3}\-[0-9]{1,3}\] to Mana|\(\+[0-9]{1,3} per Level\)|Adds [0-9]{1,3} to [0-9]{1,3}|\(\[[0-9\-]{1,8}\] charges\)|Level [\[\]0-9\-]{1,8}|Socketed \([\[\]0-9\-]{1,8}\)|\"|Repairs [0-9]{1,2}|to Light Radius\,|\, \[[0-9\-]{1,8}\]\% Slower Stamina Drain|[\+\[\]0-9\-a-zA-Z\w].+\,|\(\+[0-9]{1,3}\.[0-9]{1,3} per Level\)|\([a-zA-Z0-9\[\]\-\%\+\.\s].+\)|[0-9]{0,3}\.[0-9]{1,3} Seconds|Level\-Up.*|to \[Random.*/g;

       let cleanGearAffixes = resp[0].data.map(gear => {
            let afterAttributes = gear.afterAttributes.split(",")
            let newAttributes = gear.newAttributes.split(",")

            if(afterAttributes.length > 0) {
                return afterAttributes.map(attr => {
                   return attr.replaceAll(bigRegex, "").trim().split("  ")[0]
                });
            } else if(newAttributes.length > 0) {
                 return newAttributes.map(attr => {
                   return attr.replaceAll(bigRegex, "").trim().split("  ")[0]
                });
            }
        }).flat();

        resp[0].data.forEach(gear => { cleanGearAffixes.push(gear.gearId.name) });
        resp[0].data.forEach(gear => { cleanGearAffixes.push(gear.gearId.itemCategory) });

       let cleanAffixes = resp[1].data.map(affix =>
            affix.attributes.replaceAll(bigRegex, "").trim().split("  ")[0]
        )

        let affixes = [];
        cleanAffixes.forEach(affix => {
            if( !affixes.includes(affix) ) {
                affixes.push(affix)
            }
        });
        cleanGearAffixes.forEach(affix => {
            if( !affixes.includes(affix) ) {
                affixes.push(affix)
            }
        });

        if (active) {
            setOptions(affixes);
        }
      });

    })();

    return () => {
      active = false;
    };
  }, [loading]);

  function handleAutoCompleteChanged(event, values){
        updateValue(values);
        setInputValue(values);
        if(event.type === 'click' || event.code == 'Enter') {
            setOpen(false);
            handleSubmit(event, values);
        }
  }

  return (
      <Autocomplete
        id="asynchronous-autocomplete"
        sx={{ flex: 1 }}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        inputValue={inputValue}
        value={inputValue}
        isOptionEqualToValue={(option, value) => option === value}
        freeSolo={true}
        autoHighlight={false}
        getOptionLabel={(option) => option}
        options={options}
        loading={loading}
        onInputChange={handleAutoCompleteChanged}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <div style={{position: 'absolute', right: '0', top: 'calc(50% - 10px)', width: '10%'}}>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </div>
              ),
            }}
            sx={{ flex: 1 }}
            placeholder="Search PD2 Items & Affixes"
            background-color="#FFFFFF"
            onChange={handleTextChange}
          />
        )}
      />
    );
  }