import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export default function AllocatedSkills(props) {

    const { remainingSkillPoints, ...other } = props;

    return (
        <Box ml="3%">
            <Typography variant="subtitle2" color="#FFF">Remaining Skill Points: {remainingSkillPoints}</Typography>
        </Box>
    );

}