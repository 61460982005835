import * as React from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Badge from '@mui/material/Badge';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';


export default function CharacterBuilderDrawer(props) {

    const { titleKey, itemList, fullItemList, updateItemList, itemCategoryIcon, showModalHandler, ...other } = props;
    const [categoryOpen, setCategoryOpen] = React.useState(false);

     const handleCategoryClick = () => {
        setCategoryOpen(!categoryOpen);
    };

    const handleDeleteItemClick = (txt) => {
        if(fullItemList.filter(item => item.gearId.name === txt).length > 1) {
            var i = fullItemList.findIndex(item => item.gearId.name === txt);
            updateItemList(fullItemList.slice(i, 1));
        } else {
            updateItemList(fullItemList.filter(item => item.gearId.name !== txt));
        }
    }

    return (
     <Box>
        <ListItem key={titleKey} disablePadding>
            <ListItemButton onClick={handleCategoryClick}>
              <ListItemIcon>
                <Badge badgeContent={itemList.length} color="primary"><img src={itemCategoryIcon} width='40px' height='40px'/></Badge>
              </ListItemIcon>
              <ListItemText primary={titleKey} sx={{color: '#FFF'}} />
              {categoryOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
          </ListItem>
          <Collapse in={categoryOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {itemList.map((item, i) => {
                    return (
                        <ListItemButton key={`${item.gearId.name}-${i}`}>
                            <img src={item.imageUrl} width="7%" style={{marginRight: '5px'}} data-key={`${fullItemList.map(e => e.gearId.name).indexOf(item.gearId.name)}-${i}`} onClick={(e) => showModalHandler(e)}/>
                            <ListItemText primary={item.gearId.name} sx={{color: '#FFF'}} data-key={`${fullItemList.map(e => e.gearId.name).indexOf(item.gearId.name)}-${i}`} onClick={(e) => showModalHandler(e)}/>
                            <ListItemIcon onClick={() => handleDeleteItemClick(item.gearId.name)}><DeleteIcon /></ListItemIcon>
                        </ListItemButton>)})}
              </List>
            </Collapse>
            <Divider sx={{ backgroundColor: '#191919', color: '#191919', width: '100%', margin: 0}}/>
        </Box>
    );
}