import * as React from 'react';
import Box from '@mui/material/Box';

export default function NewCharacterPlanner(props) {
    const { children, myVal, index, ...other } = props;

    const [value, setValue] = React.useState(0);

    return (
        <Box sx={{ display: 'flex'}}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                <Box sx={{flexGrow: 3}}><img src="./blank_weapon.png" style={{border: "1px solid #AFA28D"}}/></Box>
                <Box sx={{flexGrow: 1}}><img src="./blank_gloves.png" width="58px" height="58px" style={{border: "1px solid #AFA28D"}}/></Box>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignSelf: 'flex-end', gap: '10px'}}>
                <Box><img src="./blank_ring.png" style={{border: "1px solid #AFA28D"}}/></Box>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                <Box sx={{flexGrow: 1}}><img src="./blank_helm.png" style={{border: "1px solid #AFA28D"}}/></Box>
                <Box sx={{flexGrow: 2}}><img src="./blank_armor.png" style={{border: "1px solid #AFA28D"}}/></Box>
                <Box sx={{flexGrow: 1}}><img src="./blank_belt.png" style={{border: "1px solid #AFA28D"}}/></Box>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignSelf: 'end', gap: '10px'}}>
                <Box><img src="./blank_amulet.png" style={{border: "1px solid #AFA28D"}}/></Box>
                <Box><br/></Box>
                <Box><br/></Box>
                <Box><img src="./blank_ring.png" style={{border: "1px solid #AFA28D"}}/></Box>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px'}}>
                <Box sx={{flexGrow: 3}}><img src="./blank_offhand.png" style={{border: "1px solid #AFA28D"}}/></Box>
                <Box sx={{flexGrow: 1}}><img src="./blank_boots.png" style={{border: "1px solid #AFA28D"}}/></Box>
            </Box>
        </Box>
    );
}